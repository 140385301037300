import { currentDate, currentTime } from '@/global/services/helpers/dates'
import common from '../common'
import { api } from '@/global/services/api'
export default {
  name: 'createConfig',

  mixins: [
    common
  ],

  computed: {
    createDetailsConfig () {
      return {
        tabs: [
          {
            title: this.$t('fleet/vehicle.basic_info'),
            apiConfig: {
              post: {
                module: 'fleet',
                route: 'travel-documents/prl-cargo',
                method: 'post'
              }
            },
            hiddenFormKeys: [
              'vehicle_id',
              'trailer_id',
              'crew_members'
            ],
            additionalSetup: async (event) => await this.callBasicInfoAdditionalSetup(event),
            additionalDataManipulation: (data) => this.handleAdditionalDataManipulation(data),
            afterSave: (event, data) => this.redirectToEdit(event, data),
            tabContent: [
              // PRL data card
              {
                title: this.$t('fleet/vehicle.basic_info'),
                subTitle: this.$t('fleet/documents/prl_cargo.data_of_prl'),
                component: this.columnsContentComponent,
                showButtons: true,
                fullWidth: true,
                useInitialAutocompletes: false,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'foreign_code',
                        label: this.$t('fleet/documents/prl_cargo.prl_no'),
                        row: 1,
                        column: 1,
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'date_time',
                        label: this.$t('fleet/documents/prl_cargo.creation_date'),
                        row: 1,
                        column: 2,
                        type: 'datetime-picker',
                        appendIcon: 'mdi-calendar-month',
                        format: 'DD.MM.YYYY. HH:mm',
                        initialValue: currentDate('DD.MM.YYYY.') + ' ' + currentTime('HH:mm'),
                        placeholder: 'DD.MM.YYYY. HH:mm',
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'status_name',
                        label: this.$t('fleet/vehicle.status'),
                        row: 1,
                        column: 3,
                        initialValue: 'U obradi',
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'transport_type',
                        label: this.$t('fleet/documents/prl_cargo.transport_type'),
                        row: 1,
                        column: 4,
                        options: {
                          custom_display_value: (value) => {
                            return value ? this.$t(value) : ''
                          }
                        },
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'company_name',
                        label: this.$t('fleet/documents/prl_cargo.company_name_and_address'),
                        row: 2,
                        column: 1,
                        colSpan: 2,
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'organizational_unit',
                        label: this.$t('fleet.organizational_unit'),
                        row: 2,
                        column: 3,
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'department',
                        label: this.$t('fleet.department'),
                        row: 2,
                        column: 4,
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'relation',
                        label: this.$t('fleet/documents/prl_cargo.relation_or_direction'),
                        row: 3,
                        column: 1,
                        placeholder: this.$t('fleet/documents/prl_cargo.relation_or_direction_placeholder'),
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Crew members card
              {
                subTitle: this.$t('fleet/documents/prl_cargo.crew_members'),
                component: this.columnsContentComponent,
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 4,
                    fields: [
                      {
                        key: 'driver',
                        label: this.$t('fleet/vehicle.driver'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'crew_member_1',
                        label: this.$t('fleet/documents/prl_cargo.other_crew_members').replace(':number', 1),
                        placeholder: this.$t('fleet/documents/prl_cargo.other_crew_members_placeholder').replace(':number', 1),
                        type: 'autocomplete',
                        autocomplete_options: {
                          module: 'people',
                          route: 'people/autocomplete',
                          fetchInitial: false,
                          returnValue: 'object'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'crew_member_2',
                        label: this.$t('fleet/documents/prl_cargo.other_crew_members').replace(':number', 2),
                        placeholder: this.$t('fleet/documents/prl_cargo.other_crew_members_placeholder').replace(':number', 2),
                        type: 'autocomplete',
                        autocomplete_options: {
                          module: 'people',
                          route: 'people/autocomplete',
                          fetchInitial: false,
                          returnValue: 'object'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'crew_member_3',
                        label: this.$t('fleet/documents/prl_cargo.other_crew_members').replace(':number', 3),
                        placeholder: this.$t('fleet/documents/prl_cargo.other_crew_members_placeholder').replace(':number', 1),
                        type: 'autocomplete',
                        autocomplete_options: {
                          module: 'people',
                          route: 'people/autocomplete',
                          fetchInitial: false,
                          returnValue: 'object'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              },
              // Vehicle and trailer data card
              {
                subTitle: this.$t('fleet/documents/prl_cargo.vehicle_and_trailer_data'),
                component: this.columnsContentComponent,
                fullWidth: true,
                rows: [
                  {
                    type: 'fields',
                    columnsCount: 3,
                    fields: [
                      {
                        key: 'vehicle_brand',
                        label: this.$t('fleet/documents/prl_cargo.vehicle_brand'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'vehicle_registration',
                        label: this.$t('fleet/vehicle.registration'),
                        placeholder: this.$t('fleet/documents/prl_cargo.registration_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onVehicleRegistrationSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: (item) => {
                            if (item && item.vehicle_registration) {
                              return item.vehicle_registration
                            }
                          },
                          additionalQueryParams: {
                            by: 'registration',
                            vehicle_type: 'vehicle',
                            document_type: 'PRL_CARGO'
                          },
                          returnValue: 'object',
                          form_value: 'vehicle_registration'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'vehicle_garage_number',
                        label: this.$t('fleet/vehicle_attribute.garage_number'),
                        placeholder: this.$t('fleet/documents/prl_cargo.garage_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onVehicleGarageNumberSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: (item) => {
                            if (item && item.vehicle_garage_number) {
                              return item.vehicle_garage_number
                            }
                          },
                          additionalQueryParams: {
                            by: 'garage_number',
                            vehicle_type: 'vehicle',
                            document_type: 'PRL_CARGO'
                          },
                          returnValue: 'object',
                          form_value: 'vehicle_garage_number'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'trailer_brand',
                        label: this.$t('fleet/documents/prl_cargo.trailer_brand'),
                        creatable: true,
                        editable: false,
                        visible: true
                      },
                      {
                        key: 'trailer_registration',
                        label: this.$t('fleet/vehicle.registration'),
                        placeholder: this.$t('fleet/documents/prl_cargo.registration_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onTrailerRegistrationSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: (item) => {
                            if (item && item.trailer_registration) {
                              return item.trailer_registration
                            }
                          },
                          additionalQueryParams: {
                            by: 'registration',
                            vehicle_type: 'trailer',
                            document_type: 'PRL_CARGO'
                          },
                          returnValue: 'object',
                          form_value: 'trailer_registration'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      },
                      {
                        key: 'trailer_garage_number',
                        label: this.$t('fleet/vehicle_attribute.garage_number'),
                        placeholder: this.$t('fleet/documents/prl_cargo.garage_number_placeholder'),
                        type: 'autocomplete',
                        onSelect: (event) => this.onTrailerGarageNumberSelect(event),
                        autocomplete_options: {
                          module: 'fleet',
                          route: 'travel-documents/get-vehicle',
                          custom_display_value: (item) => {
                            if (item && item.trailer_garage_number) {
                              return item.trailer_garage_number
                            }
                          },
                          additionalQueryParams: {
                            by: 'garage_number',
                            vehicle_type: 'trailer',
                            document_type: 'PRL_CARGO'
                          },
                          returnValue: 'object',
                          form_value: 'trailer_garage_number'
                        },
                        creatable: true,
                        editable: true,
                        visible: true
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            title: this.$t('fleet/documents/prl_cargo.vehicle_movement'),
            disabled: true
          },
          {
            title: this.$t('fleet/documents/prl_cargo.garage'),
            disabled: true
          },
          {
            title: this.$t('fleet/documents/prl_cargo.fuel_and_lubricant'),
            disabled: true
          },
          {
            title: this.$t('fleet/documents/prl_cargo.mileage_of_tires'),
            disabled: true
          },
          {
            title: this.$t('fleet/documents/prl_cargo.planned_completion'),
            disabled: true
          }
        ],
        breadcrumbs: {
          items: [
            {
              text: this.$t('fleet.travel_documents')
            },
            {
              text: this.$t('fleet.prl_cargo')
            },
            {
              text: this.$t('fleet/documents/prl_cargo.add_new_prl'),
              bolded: true
            }
          ]
        }
      }
    }
  },

  methods: {
    async callBasicInfoAdditionalSetup (event) {
      const { initialAutocompletes, formDataForSend } = event || {}

      const { data: peopleData, has_more: hasMore = false } = await api().people.get('people/autocomplete', {})
      if (peopleData && peopleData.length && initialAutocompletes) {
        this.$set(initialAutocompletes, 'crew_member_1', {
          data: peopleData,
          hasMore: hasMore
        })

        this.$set(initialAutocompletes, 'crew_member_2', {
          data: peopleData,
          hasMore: hasMore
        })

        this.$set(initialAutocompletes, 'crew_member_3', {
          data: peopleData,
          hasMore: hasMore
        })
      }

      const { data: driverCompanyData } = await api().fleet.get('travel-documents/get-driver-company-from-user', {})

      if (driverCompanyData) {
        const { person, company } = driverCompanyData || {}
        this.$set(formDataForSend, 'driver', person?.name || '')
        this.$set(formDataForSend, 'company_name', company?.name || '')
      }
    }
  }
}
