import { api } from '@/global/services/api'
import common from '../common'

export default {
  name: 'vehicleMovementConfig',

  mixins: [
    common
  ],

  data () {
    return {
      vehicleMovementPermissions: {}
    }
  },

  computed: {
    vehicleMovement () {
      return {
        title: this.$t('fleet/documents/prl_cargo.vehicle_movement'),
        key: 'vehicle-movement',
        tabContent: [
          {
            component: this.tableGridContentComponent,
            apiConfig: {
              get: {
                module: 'fleet',
                route: `travel-documents/prl-cargo/${this.$route.params.id}/movement`,
                method: 'get'
              }
            },
            openDetail: {
              id: this.$route.query.item_id ?? null,
              tabKey: 'vehicle-movement',
              config: this.vehicleMovementDetails
            },
            underTableContentStyle: {
              height: '200px',
              marginTop: '15px'
            },
            permissions: this.vehicleMovementPermissions,
            onDataReceived: (data) => {
              const { meta } = data || {}
              const { permissions } = meta || {}
              const { status } = data || null
              this.currentStatusId = status
              this.$set(this.vehicleMovementPermissions, 'canEdit', permissions?.canEdit !== undefined ? permissions.canEdit : false)
              this.$set(this.vehicleMovementPermissions, 'canEditMovementItem', permissions?.canEditMovementItem !== undefined ? permissions.canEditMovementItem : false)
              this.$set(this.vehicleMovementPermissions, 'canDeleteMovementItem', permissions?.canDeleteMovementItem !== undefined ? permissions.canDeleteMovementItem : false)
              this.$set(this.vehicleMovementPermissions, 'canChangeStatus', permissions?.canChangeStatus !== undefined ? permissions.canChangeStatus : false)
            },
            rowClick: (event) => this.showVehicleMovementDetails(event, 'showTableDetailsPage'),
            search_and_buttons_config: {
              additional_buttons: [
                {
                  type: 'base',
                  label: this.$t('fleet/documents/prl_cargo.delete_all'),
                  prependIcon: 'mdi-delete',
                  click: (event) => this.deleteVehicleMovementTable(event)
                },
                {
                  type: 'base',
                  label: this.$t('fleet/documents/prl_cargo.get_data'),
                  prependIcon: 'mdi-download',
                  click: (event) => this.fillVehicleMovementTable(event)
                },
                {
                  type: 'base',
                  label: this.$t('fleet/documents/prl_cargo.view_pdf'),
                  prependIcon: 'mdi-file-pdf-box',
                  click: () => this.onVehicleMovementViewPdf()
                },
                {
                  type: 'base',
                  label: this.$t('fleet/documents/prl_cargo.change_status'),
                  prependIcon: 'mdi-swap-horizontal',
                  visible: (event) => {
                    const { permissions } = event || {}

                    return permissions && permissions.canChangeStatus
                  },
                  click: (event) => this.onChangeStatus(event, 'vehicle-movement')
                }
              ],
              newClick: (event) => this.showVehicleMovementNewPage(event, 'showTableNewPage')
            },
            title_and_back_config: {
              title: this.$t('fleet/documents/prl_cargo.vehicle_movement'),
              showBackButton: {
                uiViewName: 'PrlCargo'
              }
            },
            filters_and_columns_visibility_config: {
              showRefreshButton: false,
              showFiltersButton: false,
              showColumnsVisibilityButton: false
            },
            tableConfig: {
              styles: {
                useFixedHeaderWidth: true,
                dataRowHeight: '64px',
                headersHeight: '120px',
                summaryHeight: '64px',
                borderedCells: true
              },
              cellsConfig: {
                rb: {
                  style: {
                    textAlign: 'center'
                  }
                },
                date: {
                  style: {
                    textAlign: 'center'
                  }
                },
                bill_of_lading_number: {
                  style: {
                    textAlign: 'center'
                  }
                },
                vehicle_movement: {
                  style: {
                    textAlign: 'center'
                  }
                },
                odometer: {
                  style: {
                    textAlign: 'center'
                  }
                },
                arrival_at: {
                  style: {
                    textAlign: 'center'
                  }
                },
                departure_at: {
                  style: {
                    textAlign: 'center'
                  }
                },
                loading_at: {
                  style: {
                    textAlign: 'center'
                  }
                },
                unloading_at: {
                  style: {
                    textAlign: 'center'
                  }
                },
                other_time: {
                  style: {
                    textAlign: 'center'
                  }
                },
                cargo_type: {
                  style: {
                    textAlign: 'center'
                  }
                },
                distance_with_cargo: {
                  style: {
                    textAlign: 'center'
                  }
                },
                distance_without_cargo: {
                  style: {
                    textAlign: 'center'
                  }
                },
                vehicle_loaded_weight: {
                  style: {
                    textAlign: 'center'
                  }
                },
                vehicle_unloaded_weight: {
                  style: {
                    textAlign: 'center'
                  }
                },
                vehicle_carrying_weight: {
                  style: {
                    textAlign: 'center'
                  }
                },
                vehicle_t_per_km: {
                  style: {
                    textAlign: 'center'
                  }
                },
                trailer_t_per_km: {
                  style: {
                    textAlign: 'center'
                  }
                }
              },
              headers: [
                {
                  label: this.$t('fleet/documents/prl_cargo.rb'),
                  key: 'id',
                  style: {
                    width: '70px',
                    textAlign: 'center',
                    borderRight: '1px solid #D1D5DB',
                    borderBottom: '1px solid #D1D5DB'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.date'),
                  key: 'date',
                  style: {
                    width: '120px',
                    textAlign: 'center',
                    borderRight: '1px solid #D1D5DB',
                    borderBottom: '1px solid #D1D5DB'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.bill_of_lading_number'),
                  key: 'bill_of_lading_number',
                  style: {
                    width: '130px',
                    textAlign: 'center',
                    borderRight: '1px solid #D1D5DB',
                    borderBottom: '1px solid #D1D5DB'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.vehicle_movement_table'),
                  key: 'vehicle_movement',
                  style: {
                    width: '296px',
                    textAlign: 'center',
                    borderRight: '1px solid #D1D5DB',
                    borderBottom: '1px solid #D1D5DB'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.odometer'),
                  key: 'odometer',
                  style: {
                    width: '130px',
                    textAlign: 'center',
                    borderRight: '1px solid #D1D5DB',
                    borderBottom: '1px solid #D1D5DB'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.arrival_at'),
                  key: 'arrival_at',
                  style: {
                    width: '130px',
                    textAlign: 'center',
                    borderRight: '1px solid #D1D5DB',
                    borderBottom: '1px solid #D1D5DB'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.departure_at'),
                  key: 'departure_at',
                  style: {
                    width: '130px',
                    textAlign: 'center',
                    borderRight: '1px solid #D1D5DB',
                    borderBottom: '1px solid #D1D5DB'
                  },
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.vehicle_condition'),
                  style: {
                    width: '560px',
                    height: '60px',
                    borderRight: '1px solid #D1D5DB',
                    borderBottom: '1px solid #D1D5DB'
                  },
                  subHeaders: [
                    {
                      label: this.$t('fleet/documents/prl_cargo.loading_time'),
                      key: 'loading_at',
                      style: {
                        height: '60px',
                        borderRight: '1px solid #D1D5DB'
                      }
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.unloading_time'),
                      key: 'unloading_at',
                      style: {
                        height: '60px',
                        borderRight: '1px solid #D1D5DB'
                      }
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.other_time'),
                      key: 'other_time',
                      style: {
                        height: '60px',
                        borderRight: '1px solid #D1D5DB'
                      }
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.cargo_type'),
                      key: 'cargo_type',
                      style: {
                        height: '60px',
                        borderRight: '1px solid #D1D5DB'
                      }
                    }
                  ],
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.crossed_km'),
                  style: {
                    width: '240px',
                    height: '60px',
                    borderRight: '1px solid #D1D5DB',
                    borderBottom: '1px solid #D1D5DB'
                  },
                  subHeaders: [
                    {
                      label: this.$t('fleet/documents/prl_cargo.with_cargo'),
                      key: 'distance_with_cargo',
                      style: {
                        height: '60px',
                        borderRight: '1px solid #D1D5DB'
                      }
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.without_cargo'),
                      key: 'distance_without_cargo',
                      style: {
                        height: '60px',
                        borderRight: '1px solid #D1D5DB'
                      }
                    }
                  ],
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.transported_cargo'),
                  style: {
                    width: '360px',
                    height: '60px',
                    borderRight: '1px solid #D1D5DB',
                    borderBottom: '1px solid #D1D5DB'
                  },
                  subHeaders: [
                    {
                      label: this.$t('fleet/documents/prl_cargo.loaded'),
                      key: 'vehicle_loaded_weight',
                      style: {
                        height: '60px',
                        borderRight: '1px solid #D1D5DB'
                      }
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.unloaded'),
                      key: 'vehicle_unloaded_weight',
                      style: {
                        height: '60px',
                        borderRight: '1px solid #D1D5DB'
                      }
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.drive'),
                      key: 'vehicle_carrying_weight',
                      style: {
                        height: '60px',
                        borderRight: '1px solid #D1D5DB'
                      }
                    }
                  ],
                  always_visible: true
                },
                {
                  label: this.$t('fleet/documents/prl_cargo.executed_km'),
                  style: {
                    width: '240px',
                    height: '60px',
                    borderRight: '1px solid #D1D5DB',
                    borderBottom: '1px solid #D1D5DB'
                  },
                  subHeaders: [
                    {
                      label: this.$t('fleet/documents/prl_cargo.motor_vehicle'),
                      key: 'vehicle_t_per_km',
                      style: {
                        height: '60px',
                        borderRight: '1px solid #D1D5DB'
                      }
                    },
                    {
                      label: this.$t('fleet/documents/prl_cargo.connected_vehicle'),
                      key: 'trailer_t_per_km',
                      style: {
                        height: '60px',
                        borderRight: '1px solid #D1D5DB'
                      }
                    }
                  ],
                  always_visible: true
                }
              ],
              actionsConfig: [
                {
                  label: this.$t('base.edit'),
                  icon: 'mdi-pencil-box',
                  disabled: (event) => {
                    const { permissions } = event || {}

                    return permissions && !permissions.canDeleteMovementItem
                  },
                  click: (event) => this.onEditVehicleMovement(event)
                },
                {
                  label: this.$t('base.delete'),
                  icon: 'mdi-delete',
                  disabled: (event) => {
                    const { permissions } = event || {}

                    return permissions && !permissions.canDeleteMovementItem
                  },
                  click: (event) => this.onVehicleMovementRemove(event)
                }
              ],
              summaryColumns: [
                'label',
                'odometer_difference',
                'arrival',
                'departure',
                'loading_sum',
                'unloading_sum',
                'other_time_sum',
                'cargo_type',
                'distance_with_cargo_sum',
                'distance_without_cargo_sum',
                'loaded_sum',
                'unloaded_sum',
                'vehicle_carrying_sum',
                'vehicle_t_per_km_sum',
                'trailer_t_per_km_sum',
                'actions'
              ],
              summaryConfig: {
                label: {
                  cols: 4
                }
              }
            }
          },
          {
            component: this.vehicleMovementSignatureTable
          }
        ]
      }
    },

    vehicleMovementDetails () {
      return {
        title: this.$t('fleet/documents/prl_cargo.vehicle_movement_data'),
        apiConfig: {
          post: {
            module: 'fleet',
            route: (data, isCreate) => {
              if (isCreate) {
                return `travel-documents/prl-cargo/${this.$route.params.id}/movement`
              }
              else {
                return data && data.id ? `travel-documents/prl-cargo/${this.$route.params.id}/movement/${data.id}` : ''
              }
            },
            method: 'post'
          }
        },
        tabContent: [
          {
            subTitle: this.$t('fleet/documents/prl_cargo.vehicle_movement'),
            showButtons: true,
            type: 'columns',
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 3,
                fields: [
                  {
                    key: 'date',
                    label: this.$t('fleet/documents/prl_cargo.date'),
                    type: 'datetime-picker',
                    subType: 'date',
                    appendIcon: 'mdi-calendar-month',
                    format: 'DD.MM.YYYY.',
                    placeholder: 'DD.MM.YYYY.',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'bill_of_lading_number',
                    label: this.$t('fleet/documents/prl_cargo.bill_of_lading_number'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'vehicle_movement',
                    label: this.$t('fleet/documents/prl_cargo.vehicle_movement'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'odometer',
                    label: this.$t('fleet/documents/prl_cargo.odometer'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'arrival_at',
                    label: this.$t('fleet/documents/prl_cargo.arrival_at'),
                    type: 'datetime-picker',
                    subType: 'time',
                    appendIcon: 'mdi-clock',
                    format: 'HH:mm',
                    placeholder: 'HH:mm',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'departure_at',
                    label: this.$t('fleet/documents/prl_cargo.departure_at'),
                    type: 'datetime-picker',
                    subType: 'time',
                    appendIcon: 'mdi-clock',
                    format: 'HH:mm',
                    placeholder: 'HH:mm',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'expense_location',
                    label: this.$t('fleet/documents/prl_cargo.expense_location'),
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          },
          {
            subTitle: this.$t('fleet/documents/prl_cargo.condition_of_the_vehicle'),
            type: 'columns',
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 4,
                fields: [
                  {
                    key: 'loading_at',
                    label: this.$t('fleet/documents/prl_cargo.loading_time'),
                    type: 'datetime-picker',
                    subType: 'time',
                    appendIcon: 'mdi-clock',
                    format: 'HH:mm',
                    placeholder: 'HH:mm',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'unloading_at',
                    label: this.$t('fleet/documents/prl_cargo.unloading_time'),
                    type: 'datetime-picker',
                    subType: 'time',
                    appendIcon: 'mdi-clock',
                    format: 'HH:mm',
                    placeholder: 'HH:mm',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'other_time',
                    label: this.$t('fleet/documents/prl_cargo.other_time'),
                    type: 'datetime-picker',
                    subType: 'time',
                    appendIcon: 'mdi-clock',
                    format: 'HH:mm',
                    placeholder: 'HH:mm',
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'cargo_type',
                    label: this.$t('fleet/documents/prl_cargo.cargo_type'),
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          },
          {
            subTitle: this.$t('fleet/documents/prl_cargo.crossed_km'),
            type: 'columns',
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 4,
                fields: [
                  {
                    key: 'distance_with_cargo',
                    label: this.$t('fleet/documents/prl_cargo.with_cargo'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'distance_without_cargo',
                    label: this.$t('fleet/documents/prl_cargo.without_cargo'),
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          },
          {
            subTitle: this.$t('fleet/documents/prl_cargo.motor_vehicle_and_trailer_cargo_km'),
            type: 'columns',
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 3,
                fields: [
                  {
                    key: 'vehicle_loaded_weight',
                    label: this.$t('fleet/documents/prl_cargo.loaded_in_vehicle'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'vehicle_unloaded_weight',
                    label: this.$t('fleet/documents/prl_cargo.unloaded_from_vehicle'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'vehicle_carrying_weight',
                    label: this.$t('fleet/documents/prl_cargo.drive_in_vehicle'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'trailer_loaded_weight',
                    label: this.$t('fleet/documents/prl_cargo.loaded_in_trailer'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'trailer_unloaded_weight',
                    label: this.$t('fleet/documents/prl_cargo.unloaded_from_trailer'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'trailer_carrying_weight',
                    label: this.$t('fleet/documents/prl_cargo.drive_trailer'),
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          },
          {
            subTitle: this.$t('fleet/documents/prl_cargo.executed_km'),
            type: 'columns',
            fullWidth: true,
            rows: [
              {
                type: 'fields',
                columnsCount: 3,
                fields: [
                  {
                    key: 'vehicle_t_per_km',
                    label: this.$t('fleet/documents/prl_cargo.motor_vehicle'),
                    creatable: true,
                    editable: true,
                    visible: true
                  },
                  {
                    key: 'trailer_t_per_km',
                    label: this.$t('fleet/documents/prl_cargo.connected_vehicle'),
                    creatable: true,
                    editable: true,
                    visible: true
                  }
                ]
              }
            ]
          },
          {
            subTitle: this.$t('fleet/documents/prl_cargo.recipient'),
            type: 'columns',
            fullWidth: true,
            insideCardButtons: {
              position: 'right',
              buttons: [
                {
                  type: 'save',
                  label: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.movement_recipient_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('base.signed_it')
                  },
                  visible: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.movement_recipient_can_sign
                  },
                  disabled: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.movement_recipient_signature_image
                  },
                  prependIcon: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.movement_recipient_signature_image ? 'mdi-check' : 'mdi-draw'
                  },
                  color: this.$route.query.sign_button_color ?? '',
                  prependIconStyle: {
                    color: '#FFFFFF !important',
                    marginRight: '4px',
                    fontSize: '19px'
                  },
                  click: (event) => this.movementSign(event, 'PRL_CARGO_RECIPIENT_MOVEMENT', true)
                },
                {
                  type: 'save',
                  label: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.movement_recipient_signature_image ? this.$t('fleet/documents/prl_cargo.signed') : this.$t('fleet/documents/prl_cargo.send_for_signature')
                  },
                  visible: () => {
                    const { canEdit } = this.vehicleMovementPermissions || {}
                    return canEdit
                  },
                  prependIcon: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.movement_recipient_signature_image ? 'mdi-check' : 'mdi-send'
                  },
                  disabled: (event) => {
                    const { modifiedFormData } = event || {}
                    return modifiedFormData && !!modifiedFormData.movement_recipient_signature_image
                  },
                  prependIconStyle: {
                    color: '#FFFFFF !important',
                    marginRight: '4px',
                    fontSize: '19px'
                  },
                  click: (event) => this.onMovementSendForSignature(event)
                }
              ]
            },
            rows: [
              {
                type: 'fields',
                columnsCount: 4,
                fields: [
                  {
                    key: 'movement_recipient_signature_name',
                    label: this.$t('fleet/documents.prl_cargo_recipient_name'),
                    creatable: true,
                    editable: false,
                    visible: (event) => {
                      const { createMode } = event || {}

                      return !createMode
                    }
                  },
                  {
                    key: 'movement_recipient_signed_at',
                    label: this.$t('fleet/documents.recipient_signature_time'),
                    placeholder: 'DD.MM.YYYY HH:mm',
                    creatable: true,
                    editable: false,
                    visible: (event) => {
                      const { createMode } = event || {}

                      return !createMode
                    }
                  },
                  {
                    type: 'content-with-buttons',
                    key: 'movement_recipient_signature_image',
                    label: this.$t('fleet/signature_type.prl_cargo_recipient_movement'),
                    contentStack: 'horizontal',
                    contentType: 'image',
                    contentStyle: {
                      height: '88px',
                      border: '1px solid #E5E7EB',
                      borderRadius: '16px'
                    },
                    creatable: true,
                    editable: false,
                    visible: (event) => {
                      const { createMode } = event || {}

                      return !createMode
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },

  methods: {
    showVehicleMovementDetails (event, functionName) {
      const { index, instance } = event
      if (functionName && instance && typeof instance[functionName] === 'function') {
        instance[functionName](index, this.vehicleMovementDetails)
      }
    },

    onEditVehicleMovement (event) {
      const { index, instance } = event || {}

      if (instance && instance.showTableDetailsPage && typeof instance.showTableDetailsPage === 'function') {
        instance.showTableDetailsPage(index, this.vehicleMovementDetails)
      }
    },

    async onVehicleMovementRemove (event) {
      try {
        this.$set(this.informationDialogData, 'message', this.$t('fleet/documents/prl_cargo.delete_vehicle_movement_message'))
        this.$set(this.informationDialogData, 'cancelText', this.$t('base.cancel'))
        this.$set(this.informationDialogData, 'confirmText', this.$t('base.confirm'))
        this.$set(this.informationDialogData, 'title', this.$t('fleet/documents/prl_cargo.delete_vehicle_movement_title'))
        this.$set(this.informationDialogData, 'callback', async () => await this.onVehicleMovementRemoveCallback(event))
        this.$set(this.informationDialogData, 'show', true)
      }
      catch (exception) {
        console.log(exception)
      }
    },

    async onVehicleMovementRemoveCallback (event) {
      try {
        const { id } = event?.data || {}
        const { fetchTableData } = event || {}

        if (id) {
          await api().fleet.delete(`travel-documents/prl-cargo/${this.$route.params.id}/movement/${id}`)

          this.$set(this.informationDialogData, 'show', false)
          if (fetchTableData && typeof fetchTableData === 'function') {
            fetchTableData()
          }
        }
      }
      catch (exception) {
        this.$set(this.informationDialogData, 'show', false)
        console.log(exception)
      }
    },

    showVehicleMovementNewPage (event, functionName) {
      if (functionName && event && typeof event[functionName] === 'function') {
        event[functionName](this.vehicleMovementDetails)
      }
    },

    async deleteVehicleMovementTable (event) {
      try {
        const { tableData } = event || {}

        if (tableData?.data?.length || tableData?.length) {
          this.$set(this.informationDialogData, 'message', this.$t('fleet/documents/prl_cargo.overwriting_existing_data_message'))
          this.$set(this.informationDialogData, 'cancelText', this.$t('base.cancel'))
          this.$set(this.informationDialogData, 'confirmText', this.$t('base.confirm'))
          this.$set(this.informationDialogData, 'title', this.$t('base.warning'))
          this.$set(this.informationDialogData, 'callback', async () => await this.deleteVehicleMovementTableCallback(event))
          this.$set(this.informationDialogData, 'show', true)
        }
        else {
          await this.deleteVehicleMovementTableCallback(event)
        }
      }
      catch (exception) {
        console.log(exception)
      }
    },

    async deleteVehicleMovementTableCallback (event) {
      try {
        const { fetchData } = event || {}

        await api().fleet.delete(`travel-documents/prl-cargo/${this.$route.params.id}/movement/delete-all`)

        this.$set(this.informationDialogData, 'show', false)
        if (fetchData && typeof fetchData === 'function') {
          event.fetchTableDataFromOutside = true
          await fetchData()
        }
      }
      catch (exception) {
        this.$set(this.informationDialogData, 'show', false)
        console.log(exception)
      }
    },

    async fillVehicleMovementTable (event) {
      try {
        const { fetchData } = event || {}

        await api().fleet.get(`travel-documents/prl-cargo/${this.$route.params.id}/movement/from-trips`)

        this.$set(this.informationDialogData, 'show', false)
        if (fetchData && typeof fetchData === 'function') {
          event.fetchTableDataFromOutside = true
          await fetchData()
        }
      }
      catch (exception) {
        this.$set(this.informationDialogData, 'show', false)
        console.log(exception)
      }
    },

    async onVehicleMovementViewPdf () {
      await this.exportData(this.$route.params.id)
    },

    onMovementSendForSignature (event) {
      if (event && event.formData) {
        this.$set(this.sendForSignatureDialogData, 'additionalObject', {
          foreign_id: event?.formData?.id
        })
        this.$set(this.sendForSignatureDialogData, 'instance', event?.instance?.$parent?.$parent?.$parent)
      }
      else if (event && event.data) {
        this.$set(this.sendForSignatureDialogData, 'additionalObject', {
          foreign_id: event?.data?.id
        })
        this.$set(this.sendForSignatureDialogData, 'instance', event?.instance)
      }

      this.$set(this.sendForSignatureDialogData, 'signatureType', 'PRL_CARGO_RECIPIENT_MOVEMENT')
      this.$set(this.sendForSignatureDialogData, 'show', true)
    },

    movementSign (event, signatureType = '', fetchSignature = false) {
      if (event && event.formData) {
        this.$set(this.signatureDialogData, 'additionalObject', {
          foreign_id: event?.formData?.id
        })
      }
      const config = {
        title: this.$t('fleet/documents/prl_cargo.sign_document'),
        confirmDialogTitle: this.$t('fleet.sign_document'),
        apiConfig: {
          get: {
            module: 'fleet',
            route: 'travel-documents/get-driver-company-from-user',
            method: 'get'
          },
          post: {
            module: 'fleet',
            route: 'travel-documents/signature',
            method: 'post'
          }
        }
      }
      this.$set(this.signatureDialogData, 'config', config)
      this.$set(this.signatureDialogData, 'fetchSignature', fetchSignature)
      this.$set(this.signatureDialogData, 'signatureType', signatureType)
      this.$set(this.signatureDialogData, 'tabKey', 'vehicle-movement')
      this.$set(this.signatureDialogData, 'successCallback', async () => await this.successCallback(event))
      this.$set(this.signatureDialogData, 'show', true)
    },

    async successCallback (event) {
      const { showTableDetailsPageFromOutside, fetchData } = event?.instance?.$parent?.$parent?.$parent || {}

      if (this.$route.query.item_id && showTableDetailsPageFromOutside && fetchData) {
        await fetchData()
        showTableDetailsPageFromOutside(this.$route.query.item_id, this.vehicleMovementDetails)
      }
    }
  }
}
