import { defaultMapSetup } from '@/global/common/mapConfig'
import { isObject } from 'lodash'

const store = {
  namespaced: true,

  state: {
    mapType: {},
    tileOptions: {
      maxZoom: defaultMapSetup.maxZoom,
      maxNativeZoom: defaultMapSetup.maxNativeZoom
    }
  },

  getters: {
    getMapType: state => state.mapType,
    getTileOptions: state => state.tileOptions
  },

  actions: {
    setMapType ({ commit }, payload) {
      commit('SET_MAP_TYPE', payload)
    },

    setTileOptions ({ commit }, payload) {
      commit('SET_TILE_OPTIONS', payload)
    }
  },

  mutations: {
    SET_MAP_TYPE: (state, payload) => (state.mapType = payload),

    SET_TILE_OPTIONS: (state, payload) => {
      if (payload && isObject(payload)) {
        state.tileOptions = {
          ...payload,
          ...{
            styles: []
          }
        }
      }
    }
  }
}

export default store
