import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/hr' // Import Croatian locale
import 'dayjs/locale/en' // Ensure English locale is available

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(relativeTime)
const tz = dayjs.tz.guess()

export const defaultDateFormat = 'DD.MM.YYYY'
export const defaultTimeFormat = 'HH:mm:ss'
export const defaultDateTimeFormat = `${defaultDateFormat} ${defaultTimeFormat}`

export const getEnglishLocaleCodes = () => [
  'en-au',
  'en-bz',
  'en-ca',
  'en-cb',
  'en-gb',
  'en-in',
  'en-ie',
  'en-jm',
  'en-nz',
  'en-ph',
  'en-za',
  'en-tt',
  'en-us'
]

export const dateDifferenceByTypes = () => [
  'day',
  'week',
  'Quarter',
  'Month',
  'year',
  'hour',
  'minute',
  'second',
  'millisecond'
]

export function formatSqlDate (date, format = defaultDateFormat) {
  return dayjs(date)
    .tz(tz)
    .format(format)
}
export function getDateDifference (date1, date2, differenceBy, format) {
  const date1Formatted = date1 && format ? dayjs(date1, format) : dayjs(date1)
  const date2Formatted = date2 && format ? dayjs(date2, format) : dayjs(date2)

  return date1Formatted.isValid() && date2Formatted.isValid() && differenceBy && dateDifferenceByTypes().includes(differenceBy)
    ? date2Formatted.diff(date1Formatted, differenceBy)
    : null
}

/**
 * Calculate the difference between two datetime strings
 * @param {string} date1 - First date in ISO format (e.g., "2024-10-28T15:02:35.000000Z")
 * @param {string} date2 - Second date in ISO format (e.g., "2024-10-28T07:14:21.000000Z")
 * @param format
 * @param language
 * @returns {string} - Formatted difference ("HH:mm:ss" if <24h, otherwise "1 day", "2 days", etc.)
 */
export function getTimeDifference (date1, date2, format = defaultTimeFormat, language = 'en') {
  const d1 = dayjs(date1)
  const d2 = dayjs(date2)

  const diffInMs = d1.diff(d2)
  const diffInHours = dayjs.duration(diffInMs).asHours()

  if (diffInHours < 24) {
    return dayjs.utc(diffInMs).locale(language).format(format)
  }
  else {
    return dayjs.duration(diffInMs).locale(language).humanize() // Format as "1 day", "2 days", etc.
  }
}

export function convertDate (date = '', fromFormat = '', toFormat = '') {
  if (fromFormat && toFormat && date) {
    return dayjs(date, fromFormat).format(toFormat)
  }
}

export function currentDate (format = defaultDateFormat) {
  return dayjs().format(format)
}

export function currentTime (format = defaultTimeFormat) {
  return dayjs().format(format)
}

export function formatSqlTime (date) {
  return dayjs(date)
    .tz(tz)
    .format(defaultTimeFormat)
}

export function validateDateTimeFormat (dateString, format = defaultDateTimeFormat) {
  return dayjs(dateString, format, true).isValid()
}

export function formatTime (date, format = 'HH:mm') {
  return dayjs(date)
    .tz(tz)
    .format(format)
}

export function formatSqlDateTime (dateTime, format = null) {
  const localeDateTimeFormat = !format ? `${defaultDateFormat} ${defaultTimeFormat}` : format

  return dayjs(dateTime)
    .tz(tz)
    .format(localeDateTimeFormat)
}

// Converts datetime from 'UTC' timezone to current timezone and formats it
export function formatUtcSqlDateTime (dateTime, format = null) {
  const localeDateTimeFormat = !format ? `${defaultDateFormat} ${defaultTimeFormat}` : format

  return dayjs.tz(dateTime, 'UTC')
    .tz(tz)
    .format(localeDateTimeFormat)
}

export function formatIsoDate (date, format = 'YYYY-MM-DD') {
  return dayjs(date)
    .tz(tz)
    .format(format)
}

export function formatIsoDateCustom (date, fromFormat = undefined, toFormat = 'YYYY-MM-DD') {
  return dayjs(date, fromFormat)
    .tz(tz)
    .format(toFormat)
}

export function formatIsoTime (date, format = 'HH:mm:ss') {
  return dayjs(date)
    .tz(tz)
    .format(format)
}

export function formatIsoString (date) {
  return dayjs(date).toISOString()
}

export function parseDmyDate (date) {
  return dayjs(date, defaultDateFormat)
    .format('YYYY-MM-DD')
}

export function parseDmyDateTime (dateTime) {
  const dateTimeFormat = `${defaultDateFormat} ${defaultTimeFormat}`

  return dayjs(dateTime, dateTimeFormat)
    .format('YYYY-MM-DD HH:mm:ss')
}
